.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  list-style-type: disc;
  background-image: linear-gradient(270deg, rgb(0, 172, 239) 0.00%,rgb(0, 255, 24) 100.00%,rgb(2, 247, 150) 98.00%);
  list-style-image: none;
  list-style-position: outside;
}
.container1 {
  top: 0px;
  left: 0px;
  width: 1199px;
  height: 659px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.iframe {
  width: 100%;
  height: 68px;
}
.text {
  align-self: center;
}
.iframe1 {
  width: 320px;
  height: 200px;
}
